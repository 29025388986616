<template>
  <el-card
    :class="`ui-card ${noLine?'no-line':''}`"
    :style="{border: 0, ...bodyStyle}"
    shadow="never"
    :body-style="{ border: '0', ...containerStyle}"
  >
    <template v-slot:header>
      <div class="title">
        <span class="badge"></span>
        <slot name="header"></slot>
      </div>
      <div class="actions-box">
      </div>
      <slot name="actions"></slot>
    </template>
    <slot></slot>
  </el-card>
</template>

<script>
export default {
  name: "Card",
  props: {
    bodyStyle: {
      type: Object,
      default: () => { return {} },
    },
    containerStyle: {
      type: Object,
      default: () => ({})
    },
    noLine: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped lang="less">
.ui-card.no-line {
  /deep/ .el-card__header {
    border-bottom: 0;
  }
}
.ui-card {
  /deep/ .el-card__header {
    padding: 20px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 20px;
  }
  .title {
    display: flex;
    align-items: center;
    font-size: 20px;
    font-weight: 500;
  }
  .badge {
    margin-right: 30px;
    display: block;
    width: 3px;
    height: 17px;
    background: rgba(0, 164, 255, 1);
    border-radius: 1.5px;
  }
  padding: 0;
}
</style>
