<template>
  <div class="paginationContainer">
    <el-pagination
      class="pagination"
      background
      :hide-on-single-page="singlePage"
      layout="total, prev, pager, next, jumper"
      :current-page="pageInfo.currentPage"
      :page-size="pageInfo.size"
      :total="pageInfo.total"
      @current-change="handleCurrentChange"
    />
  </div>
</template>

<script>
export default {
  name: 'Pagination',
  props: {
    pageInfo: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      value: false,
    }
  },
  computed: {
    singlePage() {
      return this.pageInfo.total < this.pageInfo.size
    },
  },
  methods: {
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`)
      this.$emit('changePage', val)
    },
  },
}
</script>

<style scoped lang="less">
@import '~@/styles/variables.less';

.paginationContainer {
  display: flex;
  justify-content: flex-end;
  // width: @uni-width;
  .pagination /deep/ .number {
    background: #fff;
    border-radius: 4px;
    border: 1px solid #e0e0e0;
  }

  .pagination /deep/ .btn-quicknext {
    background: #fff;
    border-radius: 4px;
    border: 1px solid #e0e0e0;
  }

  .pagination /deep/ .btn-prev {
    background: #fff;
    border-radius: 4px;
    border: 1px solid #e0e0e0;
  }
  .pagination /deep/ .btn-next {
    background: #fff;
    border-radius: 4px;
    border: 1px solid #e0e0e0;
  }
  .pagination /deep/ .el-pagination__total {
    font-size: 14px;
    font-weight: 400;
    color: #999999;
  }
  .pagination /deep/ .el-pagination__jump {
    font-size: 14px;
    font-weight: 400;
    color: #000;
  }
}
</style>
