<template>
  <Card shadow="never" :body-style="{ border: '0', height: '100%', paddignTop: '0'}">
    <template v-slot:header>{{ $t('01e7805') }}</template>
    <div class="list-container">
      <div v-for="(item, index) in data" :key="index" class="list-item" @click="goto(item.id)">
        <div class="image">
          <img :src="item.picture" :alt="item.title" />
        </div>
        <div class="text">
          <div class="title">{{ item.title }}</div>
          <div class="desc" v-html="item.description"></div>
        </div>
        <div class="date">
          <span>{{ item.createTime | date('MM-DD') }}</span>
          <span class="sub">{{ item.createTime | date('YYYY') }}</span>
        </div>
      </div>
    </div>
    <Pagination :page-info="pageInfo" @changePage="changePage" />
  </Card>
</template>

<script>

import Pagination from "@/components/Pagination"
import Card from "@/components/Card"

import { CONSULTING_TYPE } from "@/utils/constants"

import sendImage from "@/assets/content/send.png"
import viewImage from "@/assets/content/view.png"

import api from "@/api/information.trends"

import { mapGetters, mapActions } from "vuex"

export default {
  name: "RequestManage",
  components: {
    Pagination,
    Card,
  },
  data() {
    return {
      CONSULTING_TYPE,
      viewImage,
      sendImage,
      data: [],
      pageInfo: {
        currentPage: 1,
        size: 10,
        total: 0,
      },
    }
  },
  computed: {
    ...mapGetters(["dictionaries"]),
  },
  mounted() {
    this.getList()
  },
  methods: {
    ...mapActions(["getAreaTree", "getDictionary"]),
    goto(id) {
      this.$router.push({
        path: `/information/trends/detail?id=${id}`
      })
    },
    handleSelect(id) {
      this.getList({ country: id })
    },
    getDate(dateStr) {
      const date = new Date(dateStr)
      return `${date.getMonth()}-${date.getDate()}`
    },
    getYear(dateStr) {
      const date = new Date(dateStr)
      return date.getFullYear()
    },
    cellClassName({ column }) {
      if (column.property === "title") {
        return "table-title"
      }
      return ""
    },
    changePage(page) {
      this.pageInfo.currentPage = page
      this.getList()
    },
    handleRowClick(row, column, cell) {
      // console.log(row, column, cell);
      if (column.property === "title") {
        this.$router.push({
          path: `/business/detail?id=${row.id}`,
        })
      }
    },
    getList(params = {}) {
      api
        .get({
          size: this.pageInfo.size,
          page: this.pageInfo.currentPage,
          ...params,
        })
        .then((res) => {
          if (res) {
            this.data = res.content
            this.pageInfo.total = res.page.total
          }
        })
    },
  },

}
</script>
<style scoped lang="less">
.list-container:first-child {
  padding-top: 20px;
}
.list-container {
  padding: 0 10px;
  margin-bottom: 16px;
  .list-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 40px 0;
    color: #333333;
    border-bottom: 1px dashed rgba(0, 0, 0, 0.09);
    cursor: pointer;
    .image {
      width: 192px;
      height: 102px;
      background: #eeeeee;
      border-radius: 5px;
      flex-shrink: 0;
      img {
        width: 100%;
      }
    }
    .text {
      flex-grow: 1;
      margin-left: 20px;
      .desc {
        display: block;
        margin-top: 16px;
        height: 43px;
        font-size: 14px !important;
        font-weight: 400;
        color: #999999;
        line-height: 14px;
        overflow: hidden;
        /deep/ * {
          font-size: 14px !important;
          font-weight: 400;
          font-family: Arial, "Microsoft Yahei", "Helvetica Neue", Helvetica, sans-serif;
          color: #999999;
          line-height: 14px;
        }
        /deep/ img {
          display: none;
        }
      }
    }
    .title {
      font-size: 18px;
      font-family: MicrosoftYaHei;
      font-weight: 500;
      line-height: 28px;
    }
    .date {
      margin-left: 10px;
      text-align: right;
      font-size: 20px;
      color: #666666;
      line-height: 28px;
      width: 100px;
      flex-shrink: 0;
      span {
        display: block;
      }
      span.sub {
        font-size: 14px;
        line-height: 14px;
        margin-top: 10px;
      }
    }
  }
  .list-item:hover {
    color: rgba(0, 164, 255, 1);
    .date span {
      color: rgba(0, 164, 255, 1);
    }
  }
}
</style>
