import i18n from "@/i18n"

export const COUNTRY_TYPE = {
  CHINA: '中国',
  RUSSIA: '俄罗斯',
  KAZAKHSTAN: '哈萨克斯坦',
  KYRGYZSTAN: '吉尔吉斯斯坦',
  TAJIKISTAN: '塔吉克斯坦',
  UZBEKISTAN: '乌兹别克斯坦',
  PAKISTAN: '巴基斯坦',
  INDIA: '印度',
}

export const ACCOUNT_TYPE = {
  ENTERPRISE: '企业用户',
  PERSON: '个人用户',
  LAWYER: '律师用户',
  LAW_OFFICE: '律所机构',
  TRANSLATE: '翻译机构',
  OTHER: '其他机构',
}

export const ORGANIZATION_TYPE = [
  { key: 'LAW_OFFICE', name: 'fdf04c7' },
  { key: 'TRANSLATE', name: '249b8c3' },
  // { key: 'LAW_OFFICE', name: '律所机构' },
  // { key: 'TRANSLATE', name: '翻译机构' },
  // { key: 'OTHER', name: '其他' },
]

export const STATUS = {
  get UNDER_REVIEW() { return i18n.t('e995e23') },
  get TURN_DOWN() { return i18n.t('f766986') },
  get ON_DISPLAY() { return i18n.t('f81dd58') },
  get COMMUNICATING() { return i18n.t('1854ed3') },
  get COOPERATION() { return i18n.t('6bb96c5') },
  get COMPLETED() { return i18n.t('c3c076c') },
  get END() { return i18n.t('81a33b2') },
  get USER_END() { return i18n.t('6190875') },
  get TIME_OUT() { return i18n.t('7679f0f') },
}

export const TYPE = {
  LAW: '法律需求',
  TRANSLATE: '翻译需求',
  OTHER: '其他需求',
}

export const DEMAND_TYPE = [
  { key: 'LAW', name: '80f2c9f' },
  { key: 'TRANSLATE', name: '6685da4' },
  // { key: 'LAW', name: '法律需求' },
  // { key: 'TRANSLATE', name: '翻译需求' },
  // { key: 'OTHER', name: '其他需求' },
]

export const SERVICE_TYPE = [
  { key: 'LAW', name: '法律服务' },
  { key: 'TRANSLATE', name: '翻译服务' },
  // { key: 'OTHER', name: '其他服务' },
]

export const COOPERATION_TYPE = {
  ENTERPRISE: '企业业务',
  PERSON: '个人用户',
  LAWYER: '律师用户',
  ORGANIZATION: '机构用户',
}

export const COOPERATION_STATUS = {
  get TO_BE_COMMUNICATED() {
    return i18n.t('52b1e86')
  },
  get SERVICE_COMMUNICATED() {
    return i18n.t('92c8446')
  },
  get COOPERATION() {
    return i18n.t('6bb96c5')
  },
  get NOT_COOPERATED() {
    return i18n.t('bc8e531')
  },
  get COMPLETED() {
    return i18n.t('c3c076c')
  },
  get END() {
    return i18n.t('9a44711')
  },
  get USER_END() {
    return i18n.t('6190875')
  },
}

export const CONSULTING_TYPE = {
  LAW_SERVICE: '法律服务咨询',
  COMPANY_ENTER: '企业入驻咨询',
  PLATFORM_USE: '平台使用咨询',
  OTHER: '其他',
}

export const urgentStatus = {
  get EMERGENCY() { return i18n.t('9629065') },
  get NORMAL() { return i18n.t('cc153fe') },
}

export const colorMap = {
  UNDER_REVIEW: {
    bg: 'rgba(250, 173, 20, 0.07)',
    text: 'rgba(250, 173, 20, 1)',
  },
  TURN_DOWN: { bg: 'rgba(245, 34, 45, 0.07)', text: 'rgba(245, 34, 45, 1)' },
  ON_DISPLAY: { bg: 'rgba(0, 164, 255, 0.07)', text: 'rgba(0, 164, 255, 1)' },
  COMMUNICATING: {
    bg: 'rgba(0, 164, 255, 0.07)',
    text: 'rgba(0, 164, 255, 1)',
  },
  COOPERATION: { bg: 'rgba(0, 164, 255, 0.07)', text: 'rgba(0, 164, 255, 1)' },
  COMPLETED: { bg: 'rgba(81, 197, 24, 0.07)', text: 'rgba(81, 197, 24, 1)' },
  END: { bg: 'rgba(195, 195, 195, 0.07)', text: 'rgba(195, 195, 195, 1)' },
  USER_END: { bg: 'rgba(195, 195, 195, 0.07)', text: 'rgba(195, 195, 195, 1)' },
  TIME_OUT: { bg: 'rgba(245, 34, 45, 0.07)', text: 'rgba(245, 34, 45, 1)' },
  DEFAULT: { bg: 'rgba(0, 164, 255, 0.07)', text: 'rgba(0, 164, 255, 1)' },
}

export const ORDER_STATUS = [
  { key: 'TO_BE_COMMUNICATED', get name() { return i18n.t('52b1e86') }, tag: 'tag-warning' },
  { key: 'COMMUNICATED', get name() { return i18n.t('92c8446') }, tag: 'tag-warning' },
  { key: 'IN_COOPERATION', get name() { return i18n.t('6bb96c5') }, tag: 'tag-info' },
  { key: 'COMPLETED', get name() { return i18n.t('c3c076c') }, tag: 'tag-success' },
  { key: 'CANCEL', get name() { return i18n.t('264e254') }, tag: 'tag-disabled' },
]

export const coopStatus2color = {
  TO_BE_COMMUNICATED: {
    bg: 'rgba(250, 173, 20, 0.07)',
    text: 'rgba(250, 173, 20, 1)',
  },
  SERVICE_COMMUNICATED: {
    bg: 'rgba(0, 164, 255, 0.07)',
    text: 'rgba(0, 164, 255, 1)',
  },
  COOPERATION: {
    bg: 'rgba(0, 164, 255, 0.07)',
    text: 'rgba(0, 164, 255, 1)',
  },
  NOT_COOPERATED: {
    bg: 'rgba(0, 164, 255, 0.07)',
    text: 'rgba(0, 164, 255, 1)',
  },
  COMPLETED: { bg: 'rgba(81, 197, 24, 0.07)', text: 'rgba(81, 197, 24, 1)' },
  END: { bg: 'rgba(195, 195, 195, 0.07)', text: 'rgba(195, 195, 195, 1)' },
  USER_END: { bg: 'rgba(195, 195, 195, 0.07)', text: 'rgba(195, 195, 195, 1)' },
  DEFAULT: { bg: 'rgba(0, 164, 255, 0.07)', text: 'rgba(0, 164, 255, 1)' },
}

export const READED_TYPE = [
  { value: true, name: '已读' },
  { value: false, name: '未读' },
]

/**
 * 将 键值对映射 转换为 列表。
 * @param {Object} map A dictionary of status.
 * @returns {Array} 返回 [{name, value}, ...] 键值对的列表。
 */
export function toList(map) {
  const valuesSet = new Set(Object.values(map))
  const values = Array.from(valuesSet)
  return values.map((v) => {
    const keys = Object.keys(map)
    const key = keys.find((k) => {
      return map[k] === v
    })
    return { name: v, value: key }
  })
}
